
// click and scroll to the main wrap
    const navClicks = document.querySelectorAll('li.menu-item > a');
    navClicks.forEach( data =>{
      data.addEventListener('click',(e)=>{
        e.preventDefault();
        const href = data.getAttribute('href');
        localStorage.setItem('scrollToMainWrapper', 'true');
        window.location.href = href;
      });
    });
    
    window.onload = function() {
      if(localStorage.getItem('scrollToMainWrapper') === 'true') {
        localStorage.removeItem('scrollToMainWrapper');
        const mainWrapSection = document.querySelector('.main_wrapper');
        mainWrapSection.scrollIntoView();
      }
    }

// add "active" class on the current page nav
    const currentUrl = window.location.href;
    
    const navLinks = document.querySelectorAll('.main_menu li a');

    navLinks.forEach( link =>{
      if( currentUrl === link.href ){
        link.parentNode.classList.add('active')
      }
    })



